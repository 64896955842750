import { gql } from '@apollo/client';
import { getKeysDataIfExist } from "../config/constants";

export const getLeadsVariables = (filterData) => {
  let filters = {}
  filters = {...filters, ...getKeysDataIfExist(filterData, ["adp_type", "ca_received", "title_transfer_status", "date_type", "from_date", "to_date", "status_id", "sub_status_id", "financier_id", "search_text", "lead_source", ["vehicle_transfer", "is_vehicle_transfer"], "column", "sort", "doc_status", "search_type", "disbursement", "action_type", "payment_status", "is_on_hold", "dealership_name", "lead_source_role", "lead_source_users", "vehicle_transfer_status_id", "vehicle_transfer_sub_status_id", "custom_date_type", "custom_from_date", "custom_to_date", "loan_value_received", "dealer_incentive_received", "ageing_days_di_amount", "ageing_days_loan_amount"])}
  return filters;
}
export const LEADS_QUERY = () => {
return gql`query($page_no: Float!, $user_id: Float! $leadFilterInput: LeadFilterInput!){
  leads(page_no: $page_no, user_id:$user_id, role_id:1, api_called_by:web, filter_params : $leadFilterInput){
    lead_list{
        id
        lead_code
        lead_source
        created_date
        updated_date
        status_id
        sub_status_id
        ageing_days_loan_amount
        ageing_days_di_amount
        assign_to
        status_info{
          statuslang{
            status_name
          }
        }
        sub_status_info{
          substatuslang{
            sub_status_name
          }
        }
        customer{
          name
          phone
        }
        co_borrower{
          cb_name
          cb_phone
        }
        lead_details{
          make_name
          model_name
          make_year
          variant_name
          make_id
          model_id
          make_year
          make_name
          model_name
          variant_name
          soft_approval_reference_notes
          soft_approval_reference_number
          credit_advice_reference_number
          credit_advice_reference_notes
          contract_signing_reference_number
          contract_signing_reference_notes
          car_handover_reference_notes
          orcr_received_reference_notes
          orcr_sent_for_title_transfer_reference_notes
          orcr_received_after_title_transfer_reference_notes
          orcr_verified_reference_notes
          tin_number
          accreditation_date
          loan_value
          total_dealer_incentive_of_tax
          total_payment_to_dealer
          incentive_amount_received
          plate_number
          orcr_sent_for_verification_reference_notes
          orcr_verification_status
          orcr_verification_remarks
          orcr_verification_status_reference_notes
        }
        quote_data{
          dp_amount
          loan_amount
          interest_rate
          tenure_in_month
          installment_amount
          financier_id
        }
        financier{
          financier_id
          is_proceed
          financier{
            financierlang{
              financier_name
            }
          }
        }
        leaddocs{
          doc_id
          doc_path
          
        }
      }
      pagination{
        page_no,
        totalrecords,
        nextpage,
        prevpage,
        totalpage
      }

      total_count{
        all_cases,
        lost_cases,
        open_cases,
        new_cases,
        disbursed_cases
        my_cases
      }
  }

  
  

}`;
}

export const LEADS_REMARK_QUERY=(lead_id)=>gql`
query{
  get_remarks(lead_id:${lead_id}){
    remark
    created_date
    section
    remark_type
    sub_section
  	user_details{
      added_by_name
    }
  }
}
`

export const LEADS_REMARK_QUERY_FOR_RECEIVED_AMT = gql`
  query get_remarks($lead_id: Int!, $remark_type: [Float!]!) {
    get_remarks(lead_id: $lead_id, remark_type: $remark_type) {
        remark
        created_date
        section
        remark_type
        sub_section
        user_details{
          added_by_name
        }
    }
  }`;


export const LEADS_HISTORY_QUERY=(lead_id)=>gql`
query{
  get_history(lead_id:${lead_id}, api_called_by: web) {
    lead_history {
      created_date
      user_details{
        added_by_name
      }
      status_details{
        statuslang{
          status_name
        }
      }
      sub_status_details{
        substatuslang{
          sub_status_name
        }
      }
    }
    vehicle_transfer_history {
      created_date
      user_details{
        added_by_name
      }
      status_details{
        statuslang{
          status_name
        }
      }
      sub_status_details{
        substatuslang{
          sub_status_name
        }
      }
    }
  }
}
`
export const LEAD_DETAIL_QUERY=(lead_id)=>gql`
query{
  get_lead_detail(lead_id:${lead_id},api_called_by:web){
    ca_received
    vehicle_transfer_status
    vehicle_transfer_sub_status
    id
    lead_code
    created_by_name
    lead_source
    so_id
    sm_id
    nh_id
    th_id
    doc_status  
    status_id 
    sub_status_id
    oti_status_id 
    oti_sub_status_id
    vt_status_id 
    vt_sub_status_id
    last_sub_status_id
    is_vehicle_transfer
    is_pushed_to_pb
    created_by_source
    assign_to
    title_transfer_status
    payment_status
    lead_approval_status
    customer{
      customer_id
      name
      phone
      age
      alt_phone
      dob
      gender
      customer_type
      other_nationality
      no_of_dependent
      facebook_url
      tin_number
      email
      pa_house_number
      cra_house_number
      pa_city
      cra_city
      pa_street
      cra_street
      pa_region
      cra_region
      pa_zipcode
      cra_zipcode
      pa_barangay
      cra_barangay
      house_type
      pa_sameas_cra
      primary_doc_id
      secondary_doc_id
      primary_card_no
      secondary_card_no
      marital_status
      nationality
      father_name
      mother_name
      qualification
      birthplace
      nationality
      duration_of_stay
      employment{
        employer_name
        business_address
        company_phone
        designation
        industry
        gross_monthly_income
        year_with_company
        telephone_number
        legal_form
      }
      bank_details {
        bank_id
        branch_name
        account_name
        account_type
        account_number
      }
    }
    co_borrower{
      co_borrower_id
      relationship_with_customer
      cb_name
      cb_email
      cb_phone
      cb_alt_phone
      cb_dob
      cb_birthplace
      cb_marital_status
      cb_gender
      cb_nationality
      cb_other_nationality
      cb_father_name
      cb_mother_name
      cb_customer_type
      cb_qualification
      cb_primary_doc_id
      cb_primary_card_no
      cb_secondary_doc_id
      cb_secondary_card_no
      cb_qualification
      cb_father_name
      cb_mother_name
      cb_facebook_url
      cb_tin_number
      cb_pa_house_number
      cb_cra_house_number
      cb_pa_city
      cb_cra_city
      cb_pa_street
      cb_cra_street
      cb_pa_region
      cb_cra_region
      cb_pa_zipcode
      cb_cra_zipcode
      cb_pa_barangay
      cb_cra_barangay
      cb_pa_sameas_cra
      cb_house_type
      cb_duration_of_stay
      employment_details {
        cb_employer_name
        cb_business_address
        cb_company_phone
        cb_designation
        cb_industry
        cb_gross_monthly_income
        cb_year_with_company
        cb_legal_form
      }
      bank_details {
        cb_bank_id
        cb_branch_name
        cb_account_name
        cb_account_type
        cb_account_number
      }
    }
    lead_details{
      make_id
      model_id
      variant_id
      make_year
      make_name
      model_name
      variant_name
      soft_approval_reference_notes
      soft_approval_reference_number
      credit_advice_reference_number
      credit_advice_reference_notes
      contract_signing_reference_number
      contract_signing_reference_notes
      car_handover_reference_notes
      orcr_received_reference_notes
      orcr_verified_reference_notes
      tin_number
      accreditation_date
      loan_value
      total_dealer_incentive_of_tax
      total_payment_to_dealer
      incentive_amount_received
      dealer_car_id
      color
      plate_number
      ci_name
      ci_contact
      disbursed_amount
      financing_from_date
      financing_till_date
      orcr_sent_for_title_transfer_reference_notes
      orcr_received_after_title_transfer_reference_notes
      orcr_sent_for_verification_reference_notes
      orcr_verification_status
      orcr_verification_remarks
      orcr_verification_status_reference_notes
    }
    lead_status_history{
      id
      status_id
      sub_status_id
      remarks
    }
    quote_data{
      interest_rate
      installment_amount
      out_of_town_charge
      lead_id
      car_fmv
      chattel_fee
      vehicle_usage
      vehicle_type
      other_vehicle_type
      processing_fee
      dealer_incentive
      dp_type
      dp_value
      dp_amount
      tenure_in_month
      loan_amount
      chattel_percent
      dealer_incentive_percent
      carmudi_top_up_percent
      total_dealer_incentive_net_of_tax
      dealer_incentive_from_financier
      total_payment_to_dealer
      dealer_incentive_tax_percentage 
      total_dealer_incentive
      dealer_incentive_from_carmudi
      total_payment_by_customer
      total_payment_from_financier
      dealer_incentive_from_financier_net_of_tax
      financier_id
    }
    financier{
      financier_id
      is_proceed
      financier{
        financierlang{
          financier_name
        }
      }
    }
    status_info{
      statuslang{
        status_name
      }
    }
    sub_status_info{
      substatuslang{
        sub_status_name
      }
    }
   tab_status
    lead_status_dates{
      custom_soft_approval_received_date
      custom_credit_advice_received_date
      pre_approval_received_date
      custom_contract_signed_date
      custom_car_handover_date
      custom_orcr_received_date
      custom_orcr_verified_date
      main_dealer_paid
      onhold_dealer_paid
      split1_paid
      split2_paid
      incentive_amount_received_date
      loan_amount_received
      custom_orcr_sent_for_title_transfer_date
      custom_orcr_received_after_title_transfer_date
      orcr_sent_for_verification
      orcr_verification_status
      custom_orcr_sent_for_verification_date
    }
    leaddocs{
      id
      lead_id
      financier_id
      doc_id
      parent_doc_id
      doc_path
      doc_status
      ext
      doc_source_ai
      docmaster{
        doclang{
          name
        }
      }
    }
    character_ref {
      full_name
      mobile_number
      address
      relationship
      is_skipped
    }
    sfaUserData {
      user {
        id
        name
        role_id
      }
      managers {
        id
        role_id
        name
      }
    } 
    dealerData {
      organization
      gcd_code
      dealership_contact
      dealership_email
      owner_name
      accreditation_date
      bank_name
      branch_name
      account_holder_name
      account_number
      bank_details {
        bank_name
        branch_name
        account_holder_name
        account_number
      }
      tin_id
    }
    split_payment {
      id
      lead_id
      payment_type
      bank_name
      branch_name
      account_number
      account_holder_name
      tranche_status
      amount
      status
    }
    title_payment {
      id
      lead_id
      payment_type
      bank_name
      branch_name
      account_number
      account_name
      tranche_status
      amount
      status
    }
    lead_computation_details{
      make_id
      model_id
      variant_id
      other_brand
      other_model
      other_variant
      year_of_manufacturing
      tenure_in_months
      interest_rate
      installment
      ongoing_promo
      fmv
      vat_input
      down_payment_amount
      chattel_percentage
      out_of_town_charges
      dealer_incentive_percentage
      carmudi_top_up_percentage
      dealer_incentive_tax_percentage
      loan_value
      chattel_fee
      total_fee_by_customer
      total_payment_by_customer
      dealer_incentive_from_carmudi
      dealer_incentive_from_financier
      total_dealer_incentive
      total_dealer_incentive_net_of_tax
      total_dealer_payment
      exclusive_per
      inclusive_per
      revenue_excluding_vat
      revenue_including_vat
      vat_amount
      dealer_incentive_financier_net_of_tax
      total_payment_from_financier
      vehicle_usage
      vehicle_type
      other_vehicle_type
      loan_amount_pass_through
      loan_amount_paid
      di_amount_pass_through
      dealer_amount_from_financier_percent
      dealer_amount_from_financier_amount
      di_amount_from_financier_to_carmudi_percent
      di_amount_from_financier_to_carmudi_amount
      di_amount_from_financier_to_carmudi_tax_percent
      di_amount_from_financier_to_carmudi_net_amount
      total_payment_from_financier_to_carmudi
      advance_amount_paid
      paid_through
      advance_amount
      dealer_incentive_percentage_gross
      dealer_incentive_percentage_net
      dealer_incentive_amount
      remaining_dealer_incentive_amount
      carmudi_top_up_amount
      total_dealer_incentive_percent
      total_dealer_incentive_amount
      total_dealer_incentive_net_amount
      dealer_incentive_tax_percent
      final_loan_value
      dealer_incentive_net_of_tax
      payment_to_dealer
      adp_type
      adp_parameter
      advance_loan_value_to_be_paid
      remaining_loan_value_to_be_paid
      surcharge_for_adp
      title_transfer_charge
      dealer_incentive_net_of_tax_to_be_paid
      adp_percentage_value
      dst_charges
      title_transfer_type
      orcr_docs_verification_done
    }
    lead_template_history{
      lead_id
      financier_id
      template_type
      user_email
      send_html_template
      page_type
      doc_ids
      status
      template_id
      created_date
    }
    leadinsurance {
      insurance_exists
      insurer_name
      expiration_date
      interested
    }
    soft_approval_ci_data{
      financier_id
      is_soft_approval_received
      send_to_financier
      custom_soft_approval_received_date
      soft_approval_reference_notes
      soft_approval_reference_number
      ci_name
      ci_contact
      ci_reference_notes
      ci_sub_status_reason
      credit_investigation
      is_credit_investigation_received
      credit_investigation_recieved_date
    }
  }
}
`

export const DOCUMENT_LIST=(financier_id,template_for)=>gql`query{
  
  financierListbyID(financier_id:"${financier_id}",template_for:"${template_for}"){
    id
    financier_id
    template_type
    financier_group_name
    financier_group_email
    template_body
    financier_group_mobile
  }
}
`


export const GET_SUB_STATUS = (status_ids) => gql`
  query{
    sub_status_list(status_ids:"${status_ids}"){
      substatuslang{
        sub_status_name,
        sub_status_id
      }
    }
  }`

export const MARK_AS_LOST = gql`
  mutation markAsLost($action_type: String!,$lead_id:Float! ,$sub_status_id: Float!,$comment: String!,$user_id: Float!){
    markAsLost(action_type:$action_type,lead_id:$lead_id,status_id:5,sub_status_id:$sub_status_id,remark:$comment,user_id:$user_id) {
      message
    }
  }`

export const RESET_COMPUTATION = gql`
  mutation Mark_reset_computation($reset_computation_input: ResetComputationInput!) {
    mark_reset_computation(reset_computation_input: $reset_computation_input) {
        message
    }
  }`;

export const ROLLBACK = gql`
  mutation rollback($rollback_type: String!, $lead_id:Float!){
    rollback(rollback_type: $rollback_type, lead_id: $lead_id) {
      message
    }
  }`

  export const GET_DOCUMENTS_LIST = () => gql`
  query{
    getDocumentList(api_called_by:web){
      id,
      parent_id,
      tab_name,
      doc_section,
      doc_type,   
      is_co_borrower,
      customer_type,
      is_required,
      is_required_app,
      show_in_app,
      min_upload,
      max_upload,
      doclang{
        name
        doc_id
      }
      child{
      id,
      parent_id,
      tab_name,
      doc_section,
      doc_type,   
      is_co_borrower,
      customer_type,
      is_required,
      is_required_app,
      show_in_app,
      min_upload,
      max_upload,      
      doclang{
        name
        doc_id
      }
      }
    }
  }`


  // export const SEND_DOC_TO_FINANCEIR=(docdata)=>{
  //   return gql`mutation{
  //     sendtemplatenotification(sendTemplateNotificationinput:{user_email:"${docdata?.user_email}",page_type:"${docdata?.page_type}",lead_id:${docdata?.lead_id},user_id:${docdata?.user_id}${!!docdata.template_type ? ` , template_type: [${docdata.template_type}]` : []}${!!docdata.template_id ? ` , template_id: [${docdata.template_id}]` : []},send_html_template:${docdata?.send_html_template},doc_ids:"${docdata?.doc_ids}",remarks:"${docdata?.remarks}",ci_name:"${docdata?.ci_name}",ci_contact:"${docdata?.ci_contact}"},api_called_by:"web"){
  //       message
  //     }
  //   }`
  // }


  export const SEND_DOC_TO_FINANCEIR = gql`
  mutation sendtemplatenotification($sendTemplateNotificationinput: SendTemplateNotificationInput!){
    sendtemplatenotification(sendTemplateNotificationinput : $sendTemplateNotificationinput,api_called_by:"web") {
      message
    }
  }`
  
  export const SAVE_COMPUTATION_DETAILS = gql`
  mutation saveLeadComputationDetails($leadComputationDetailsInput: LeadComputationDetailInput!){
    saveLeadComputationDetails(leadComputationDetailsInput : $leadComputationDetailsInput) {
      id
    }
  }`


  export const STATUS_QUERY=gql`query{
    status_list{
      statuslang{
        status_name,
        status_id
      }
    }
    financier_list{
      financierlang{
        financier_name,
        financier_id
      }
    }
  
    sub_status_list(status_ids:""){
      status_id
      substatuslang{
        sub_status_name,
        sub_status_id
      }
    }
  }
  
  `

  export const UPDATE_LEAD_STATUS = gql`
  mutation updateleadstatus($updateleadstatus: UpdateLeadStatus!){
    updateleadstatus(updateleadstatus : $updateleadstatus,api_called_by:"web") {
      message
    }
  }`
  
  export const SAVE_CSV_DOWNLOAD_REQ = gql`
  mutation save_csv_download_req($applied_filters: String!, $user_id:Float! , $user_email: String!, $filters: SendCsvFilters!){
    save_csv_download_req(applied_filters: $applied_filters, user_id: $user_id, user_email: $user_email, filters: $filters) {
      message
    }
  }`
  export const COMPUTATION_MASTER_DATA= (financier_id) => {return gql`query{
    computationMasterDetails(financier_id: ${financier_id}) {
      financier_id
      name
      from_year
      to_year
      interest_rate
      chattel_fee
      tenure_in_month
      processing_fee
      amount_financed_min
      amount_financed_max
      vehicle_category_id
      dealer_incentive_gross
      dealer_tax
      dealer_incentive_net
      carmudi_top_up_gross
      carmudi_tax
      carmudi_top_up_net
      total_gross_di
      di_tax
      total_net_di
      vat
      di_payment_from_financier_gross
      revenue
      di_payment_from_financier_tax
      di_payment_from_financier_net
    }
  }`
};

export const SAVE_COMPUTATION_CONFIG = gql`
mutation saveComputationConfig($fileComputationData: [CalculatorConfigInput!]!) {
  saveComputationConfig(updates: $fileComputationData) {
    id
  }
}
`;

export const CREATE_LEAD_QUERY =
  gql`mutation createLeadBasic($createLeadBasicInput: CreateLeadBasicInput!, $user_id:Float!, $role_id: Float!, $api_called_by: API_CALL_BY!){
    createLeadBasic(createLeadBasicInput: $createLeadBasicInput, user_id: $user_id, role_id: $role_id, api_called_by: $api_called_by) {
      id
      lead_code
      lead_details {
          plate_number
      }
      customer {
          phone
      }
    }
  }`;

export const VIBER_CHATBOT_DATA = (lead_id) => {
 return gql`
  query Get_viber_column {
    get_viber_column(lead_id: ${lead_id}) {
      loan_details {
        make_model_variant
        loan_id
        loan_amount
        unit_price
        tenure
        down_payment
        dealer_name
        GCD_code
      }
      personal_details {
        name
        phone
        alt_phone
        email
        dob
        birthplace
        gender
        marital_status
        nationality
        other_nationality
        customer_type
        primary_doc_id
        primary_card_no
        secondary_doc_id
        secondary_card_no
        qualification
        no_of_dependent
        father_name
        mother_name
        facebook_url
        tin_number
        insurance_exists
        insurer_name
        expiration_date
        interested
        employer_name
        business_address
        company_phone
        telephone_number
        industry
        designation
        year_with_company
        gross_monthly_income
        legal_form
        bank_id
        branch_name
        account_name
        account_type
        account_number
        re_account_number
        cra_house_number
        cra_street
        cra_region
        cra_city
        cra_barangay
        cra_zipcode
        house_type
        duration_of_stay
        pa_house_number
        pa_street
        pa_region
        pa_city
        pa_barangay
        pa_zipcode
        full_name
        mobile_number
        address
        relationship
      }
      co_borrower {
        relationship_with_customer
        cb_name
        cb_phone
        cb_alt_phone
        cb_email
        cb_dob
        cb_birthplace
        cb_gender
        cb_marital_status
        cb_nationality
        cb_other_nationality
        cb_customer_type
        cb_primary_doc_id
        cb_primary_card_no
        cb_secondary_doc_id
        cb_secondary_card_no
        cb_qualification
        cb_father_name
        cb_mother_name
        cb_facebook_url
        cb_tin_number
        cb_employer_name
        cb_business_address
        cb_company_phone
        cb_industry
        cb_designation
        cb_year_with_company
        cb_gross_monthly_income
        cb_legal_form
        cb_bank_id
        cb_branch_name
        cb_account_name
        cb_account_type
        cb_account_number
        cb_re_account_number
        cb_cra_house_number
        cb_cra_street
        cb_cra_region
        cb_cra_city
        cb_cra_barangay
        cb_cra_zipcode
        cb_house_type
        cb_duration_of_stay
        cb_pa_house_number
        cb_pa_street
        cb_pa_region
        cb_pa_city
        cb_pa_barangay
        cb_pa_zipcode
      }
      dependents {
        name
        age
        school
      }
    }
  }`
};

export const AI_JSON_DATA = (lead_id) => {
 return gql`
  query Get_viber_json {
    get_viber_json(lead_id: ${lead_id}) {
      loan_details {
        make_model_variant
        loan_id
        loan_amount
        unit_price
        tenure
        down_payment
        dealer_name
        GCD_code
      }
      personal_details {
        name
        phone
        alt_phone
        email
        dob
        birthplace
        gender
        marital_status
        nationality
        other_nationality
        customer_type
        primary_doc_id
        primary_card_no
        secondary_doc_id
        secondary_card_no
        qualification
        no_of_dependent
        father_name
        mother_name
        facebook_url
        tin_number
        insurance_exists
        insurer_name
        expiration_date
        interested
        employer_name
        business_address
        company_phone
        telephone_number
        industry
        designation
        year_with_company
        gross_monthly_income
        legal_form
        bank_id
        branch_name
        account_name
        account_type
        account_number
        re_account_number
        cra_house_number
        cra_street
        cra_region
        cra_city
        cra_barangay
        cra_zipcode
        house_type
        duration_of_stay
        pa_house_number
        pa_street
        pa_region
        pa_city
        pa_barangay
        pa_zipcode
        full_name
        mobile_number
        address
        relationship
      }
      co_borrower {
        relationship_with_customer
        cb_name
        cb_phone
        cb_alt_phone
        cb_email
        cb_dob
        cb_birthplace
        cb_gender
        cb_marital_status
        cb_nationality
        cb_other_nationality
        cb_customer_type
        cb_primary_doc_id
        cb_primary_card_no
        cb_secondary_doc_id
        cb_secondary_card_no
        cb_qualification
        cb_father_name
        cb_mother_name
        cb_facebook_url
        cb_tin_number
        cb_employer_name
        cb_business_address
        cb_company_phone
        cb_industry
        cb_designation
        cb_year_with_company
        cb_gross_monthly_income
        cb_legal_form
        cb_bank_id
        cb_branch_name
        cb_account_name
        cb_account_type
        cb_account_number
        cb_re_account_number
        cb_cra_house_number
        cb_cra_street
        cb_cra_region
        cb_cra_city
        cb_cra_barangay
        cb_cra_zipcode
        cb_house_type
        cb_duration_of_stay
        cb_pa_house_number
        cb_pa_street
        cb_pa_region
        cb_pa_city
        cb_pa_barangay
        cb_pa_zipcode
      }
      dependents {
        name
        age
        school
      }
    }
  }`
};

export const SAVE_VIBER_DATA = gql`
  mutation Update_viber_column($lead_id: Int!, $parent_object_name: String!, $changed_object: ViberColumnData!) {
    update_viber_column(
      lead_id: $lead_id
      parent_object_name: $parent_object_name
      changed_object: $changed_object
    ) {
      message
    }
  }
`;
